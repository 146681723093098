<template>
  <v-row class="align-center justify-center">
    <v-col
      cols="12"
      md="8"
      align="center"
    >
      <transition name="fade">
        <video-player
          v-show="loadedVideo"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          @loadeddata="loadedVideo = true"
        />
      </transition>
    </v-col>
    <v-col
      cols="12"
      align="center"
    >
      <div
        class="font-weight-bold white--text"
        :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2'"
      >
        {{ interactionObject.title }}
      </div>
      <div
        class="white--text"
        :class="$vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h5'"
      >
        {{ selectedInvestigation.name }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';
import { mapGetters } from 'vuex';

export default {
  components: {
    videoPlayer,
  },

  props: {
    interactionObject: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      playerOptions: {
        autoplay: false,
        muted: false,
        language: 'es',
        responsive: true,
        aspectRatio: '16:9',
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: null,
          },
        ],
        breakpoints: {
          tiny: 300,
          xsmall: 400,
          small: 500,
          medium: 600,
          large: 700,
          xlarge: 800,
          huge: 900,
        },
      },
      loadedVideo: false,
    };
  },

  computed: {
    ...mapGetters({
      selectedInvestigation: 'getSelectedInvestigation',
    }),
  },

  watch: {
    interactionObject: {
      deep: true,
      immediate: true,
      handler() {
        if (this.interactionObject.type === 'video') {
          this.playerOptions.sources[0].src = this.interactionObject.url;
        }
      },
    },
  },

};
</script>
