<template>
  <a-entity
    v-if="!item.isSvg"
    :gltf-model="`#${item.id}`"
    :position="item.position"
    :rotation="item.rotation"
    :scale="item.scale"
    @click="SET_SELECTED_INTERACTION_OBJECT(item)"
  />
  <a-image
    v-else
    :src="item.icon"
    :position="item.position"
    :rotation="item.rotation"
    :scale="item.scale"
    @click="SET_SELECTED_INTERACTION_OBJECT(item)"
  />
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '',
        type: '',
        url: '',
        icon_url: '',
        image_reference_position: 0,
        x: 0,
        y: 0,
        z: 0,
      }),
      required: true,
    },
  },

  methods: {
    ...mapMutations(['SET_SELECTED_INTERACTION_OBJECT']),
  },
};
</script>
