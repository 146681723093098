import Vue from 'vue';
import VueTypedJs from 'vue-typed-js';
import AudioVisual from 'vue-audio-visual';
import device from 'vue-device-detector';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

require('aframe');

Vue.config.productionTip = false;

Vue.use(VueTypedJs);
Vue.use(AudioVisual);
Vue.use(device);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
