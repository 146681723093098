<template>
  <v-bottom-sheet
    :value="active"
    persistent
    fullscreen
  >
    <v-card
      class="clay-card secondary bg-texture"
      tile
      height="100%"
    >
      <v-btn
        icon
        class="clay-close-btn font-weight-bold error  elevation-4"
        large
        @click="closeDialog()"
      >
        <v-icon class="white--text">
          mdi-close
        </v-icon>
      </v-btn>
      <v-container fill-height>
        <v-row
          justify="center"
          class="align-items-center"
        >
          <v-col
            cols="12"
            class="mt-4"
            align="center"
          >
            <div
              class="font-weight-black"
              :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2'"
            >
              INVESTIGACIONES
            </div>
          </v-col>
          <v-col
            cols="12"
            class="my-4"
          >
            <v-row v-if="!$vuetify.breakpoint.mobile">
              <v-col
                v-for="(investigation, index) in investigations"
                :key="index"
                cols="3"
              >
                <v-card
                  tile
                  class="investigation-card ma-2 elevation-6"
                  hover
                  @click="triggerSetSelectedInvestigation(investigation)"
                >
                  <v-card-title>
                    <v-img
                      :src="getInvestigationFrontImage(investigation)"
                      aspect-ratio="1.5"
                    />
                  </v-card-title>
                  <v-card-title
                    class="justify-center text-subtitle1 investigation-title"
                    align="center"
                  >
                    {{ investigation.name }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-slide-group show-arrows>
                  <v-slide-item
                    v-for="(investigation, index) in investigations"
                    :key="index"
                  >
                    <v-card
                      height="420"
                      width="240"
                      tile
                      class="investigation-card ma-1 elevation-4"
                      hover
                      @click="triggerSetSelectedInvestigation(investigation)"
                    >
                      <v-card-title>
                        <v-img
                          :src="getInvestigationFrontImage(investigation)"
                          aspect-ratio="1.5"
                        />
                      </v-card-title>
                      <v-card-title
                        class="justify-center text-subtitle1 investigation-title"
                        align="center"
                      >
                        {{ investigation.name }}
                      </v-card-title>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      investigations: 'getInvestigations',
    }),
  },

  methods: {
    ...mapMutations(['SET_SELECTED_INVESTIGATION', 'SET_INTERACTION_OBJECTS']),

    getInvestigationFrontImage(investigation) {
      return `${process.env.VUE_APP_API_URL}${investigation.backgroundImage}`;
    },

    triggerSetSelectedInvestigation(investigation) {
      this.SET_SELECTED_INVESTIGATION(investigation);
      if (this.$route.name === 'home') {
        this.$emit('update:active', false);
        this.$emit('selected-investigation');
      }
    },

    closeDialog() {
      this.$emit('update:active', false);
    },
  },
};
</script>
<style scoped>
    .full-height {
        height: 100vh;
    }
    .investigation-card {
        cursor: pointer;
        opacity: 0.8;
    }
    .investigation-card:hover {
        opacity: 1;
        transition: 0.2s;
        margin: 0 !important;
    }
    .investigation-title {
        word-break: break-word;
        line-height: normal;
        min-height: 128px;
    }
    .clay-close-btn {
        position: fixed;
        top: 24px !important;
        right: 24px !important;
    }
    .bg-texture{
      background-image: url('~@/assets/texture.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
</style>
