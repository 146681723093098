var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.name === 'investigaciones')?_c('v-navigation-drawer',{staticClass:"pt-4 elevation-0",attrs:{"value":true,"mini-variant":"","fixed":"","color":"transparent","floating":"","mini-variant-width":_vm.$vuetify.breakpoint.mobile ? '80' : '110',"align":"center","permanent":"","touchless":""}},[_c('v-tooltip',{attrs:{"right":"","nudge-right":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('transition',{attrs:{"name":"bounce","appear":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"clay-btn-icon font-weight-bold primary  ma-2 elevation-4",attrs:{"icon":"","large":"","to":"/"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v(" mdi-home ")])],1)],1)]}}],null,false,3870488723)},[_c('span',[_vm._v("Inicio")])]),_c('v-tooltip',{attrs:{"right":"","nudge-right":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('transition',{attrs:{"name":"bounce","appear":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"clay-btn-icon font-weight-bold primary  ma-2 elevation-4",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.investigationsDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v(" mdi-file-document-multiple ")])],1)],1)]}}],null,false,2131985503)},[_c('span',[_vm._v("Investigaciones")])]),_c('investigations-dialog-component',{attrs:{"active":_vm.investigationsDialog},on:{"update:active":function($event){_vm.investigationsDialog=$event}}}),(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"clay-btn-icon font-weight-bold primary  ma-2 elevation-4",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.SET_MOBILE_MENU_ENABLED(!_vm.mobileMenuEnabled)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(" mdi-folder-multiple-image ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"right":"","nudge-right":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('transition',{attrs:{"name":"bounce","appear":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"clay-btn-icon font-weight-bold primary  ma-2 elevation-4",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.tourDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v(" mdi-help ")])],1)],1)]}}],null,false,3365494648)},[_c('span',[_vm._v("Guía de uso")])]),_c('tour-dialog-component',{attrs:{"active":_vm.tourDialog},on:{"update:active":function($event){_vm.tourDialog=$event}}}),_c('v-tooltip',{attrs:{"right":"","nudge-right":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('transition',{attrs:{"name":"bounce","appear":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"clay-btn-icon font-weight-bold primary  ma-2 elevation-4",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.toggleSound()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.soundEnabled ? 'mdi-volume-high' : 'mdi-volume-off')+" ")])],1)],1)]}}],null,false,1783522030)},[_c('span',[_vm._v("Sonido habilitado: "+_vm._s(_vm.soundEnabled ? 'Sí' : 'No'))])])],1):_vm._e(),_c('menu-navigation-drawer-component'),_c('v-main',{class:_vm.$route.name === 'home' && 'bg-image'},[_c('router-view'),_c('FlipbookDialogComponent',{attrs:{"active":_vm.isActiveModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }