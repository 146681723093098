<template>
  <v-dialog
    v-if="interactionObject
      && interactionObject.type !== 'hemeroteca'
      && interactionObject.type !== 'denuncias'
    "
    :value="interactionObject ? true : false"
    persistent
    fullscreen
    transition="slide-y-reverse-transition"
  >
    <v-container fill-height>
      <image-interaction-object-component
        v-if="interactionObject.type === 'image'"
        :interaction-object="interactionObject"
      />
      <interview-interaction-object-component
        v-if="interactionObject.type === 'interview'"
        :interaction-object="interactionObject"
      />
      <video-interaction-object-component
        v-if="interactionObject.type === 'video'"
        ref="videoInteractionObject"
        :interaction-object="interactionObject"
      />
      <investigation-interaction-object-component
        v-if="interactionObject.type === 'investigation'"
        :interaction-object="interactionObject"
      />
      <transition
        name="bounce"
        appear
      >
        <v-btn
          icon
          class="clay-close-btn font-weight-bold error "
          large
          @click="closeDialog()"
        >
          <v-icon class="white--text">
            mdi-close
          </v-icon>
        </v-btn>
      </transition>
    </v-container>
    <v-overlay
      :z-index="-1"
      color="black"
      opacity="0.7"
      class="grayscale"
    />
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import ImageInteractionObjectComponent from './interaction-objects/ImageInteractionObjectComponent.vue';
import InterviewInteractionObjectComponent from './interaction-objects/InterviewInteractionObjectComponent.vue';
import InvestigationInteractionObjectComponent from './interaction-objects/InvestigationInteractionObjectComponent.vue';
import VideoInteractionObjectComponent from './interaction-objects/VideoInteractionObjectComponent.vue';

export default {
  components: {
    ImageInteractionObjectComponent,
    InterviewInteractionObjectComponent,
    VideoInteractionObjectComponent,
    InvestigationInteractionObjectComponent,
  },

  computed: {
    ...mapGetters({
      selectedInvestigation: 'getSelectedInvestigation',
      interactionObject: 'getSelectedInteractionObject',
    }),

    urlType() {
      if (this.interactionObject) {
        const extension = this.interactionObject.url.split('.').pop();

        if (extension === 'mp4') {
          return 'video';
        } if (extension === 'mp3') {
          return 'audio';
        } if (extension === 'png' || extension === 'jpg') {
          return 'image';
        }
        return 'unknown';
      }
      return 'unknown';
    },
  },

  methods: {
    ...mapMutations(['SET_SELECTED_INTERACTION_OBJECT']),

    closeDialog() {
      if (this.interactionObject.type === 'video') {
        this.$refs.videoInteractionObject.$refs.videoPlayer.player.pause();
      }
      this.SET_SELECTED_INTERACTION_OBJECT(null);
    },
  },
};
</script>
<style scoped>
    .clay-close-btn {
        position: fixed;
        top: 24px !important;
        right: 24px !important;
    }
    .grayscale {
        backdrop-filter: grayscale(100%);
        filter: grayscale(1);
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
