<template>
  <v-card
    v-if="images"
    class="bg-opacity"
    :class="$vuetify.breakpoint.mobile ? 'floating-card-objects-mobile' : 'floating-card-objects'"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          align="center"
        >
          <div class="text-subtitle2 font-weight-bold">
            Contenido
          </div>
          <hooper
            :vertical="true"
            style="height: 480px"
            :items-to-show="2"
            :center-mode="true"
            :transition="500"
            class="pb-12"
          >
            <slide
              v-for="(interactionObject, index) in interactionObjects"
              :key="index"
            >
              <v-img
                width="80"
                height="80"
                contain
                :src="interactionObject.icon"
                class="selectable"
                @click="SET_SELECTED_INTERACTION_OBJECT(interactionObject)"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
              <div class="text-body-2">
                {{ interactionObject.title }}
              </div>
            </slide>
            <hooper-navigation slot="hooper-addons" />
          </hooper>
        </v-col>
        <v-col cols="12">
          <v-tooltip
            v-if="selectedInvestigation"
            right
            nudge-right="12"
          >
            <template #activator="{ on, attrs }">
              <transition
                name="bounce"
                appear
              >
                <v-btn
                  class="clay-btn-icon font-weight-bold primary "
                  v-bind="attrs"
                  :href="`/pdf/${pdfName}`"
                  target="_blank"
                  v-on="on"
                >
                  <v-icon class="white--text">
                    mdi-download
                  </v-icon>
                  Descargar
                </v-btn>
              </transition>
            </template>
            <span>Descargar PDF de investigación</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },

  computed: {
    ...mapGetters({
      images: 'getImages',
      selectedInvestigation: 'getSelectedInvestigation',
      interactionObjects: 'getInteractionObjects',
    }),

    pdfName() {
      switch (this.selectedInvestigation.id) {
        case 1:
          return 'desaparecer-entre-las-mercancias.pdf';
        case 2:
          return 'escombros-de-violencia.pdf';
        case 3:
          return 'reclutamiento-en-linea.pdf';
        case 4:
          return 'la-pandemia-invisible.pdf';
        default:
          return '';
      }
    },
  },

  methods: {
    ...mapMutations(['SET_INTERACTION_OBJECTS', 'SET_SELECTED_INTERACTION_OBJECT']),

    getIconImage(media) {
      const iconImg = media.find((img) => img.collection_name === 'image_icon');

      if (iconImg) {
        return iconImg.original_url;
      } return '';
    },
  },
};
</script>
<style scoped>
    .selectable {
        cursor: pointer;
        max-width: 100%;
    }
    .floating-card-objects {
        position: fixed;
        top: 80px;
        right: 8px;
        width: 180px;
        z-index: 10;
    }
    .floating-card-objects-mobile {
        position: fixed;
        top: 24px;
        right: 50%;
        width: 180px;
        z-index: 10;
        transform: translateX(50%);
    }
    .bg-opacity{
      background-color: rgba(255, 255, 255, 0.85);
    }
</style>
