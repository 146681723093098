<template>
  <v-row class="align-center">
    <v-col
      cols="12"
      md="4"
      offset-md="1"
    >
      <v-card tile>
        <v-card-title class="justify-center">
          <v-img
            v-if="interactionObject.type === 'image'"
            :src="interactionObject.url"
            width="100%"
            :class="!$vuetify.breakpoint.mobile && 'mb-16 pa-8'"
          />
        </v-card-title>
      </v-card>
    </v-col>
    <v-col
      offset-md="1"
      :align="$vuetify.breakpoint.mobile && 'center'"
    >
      <div
        class="font-weight-bold white--text"
        :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2'"
      >
        {{ interactionObject.title }}
      </div>
      <div
        class="white--text"
        :class="$vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h5'"
      >
        {{ selectedInvestigation.name }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    interactionObject: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapGetters({
      selectedInvestigation: 'getSelectedInvestigation',
    }),
  },
};
</script>
