<template>
  <v-dialog
    v-model="menuNavigationDrawer"
    fullscreen
  >
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        fixed
        right
        v-bind="attrs"
        class="custom-fixed mt-7 white--text"
        v-on="on"
      >
        <v-icon class="mr-2">
          mdi-menu
        </v-icon> Menú
      </v-btn>
    </template>
    <v-card
      class="menu-bg-color"
      height="100%"
    >
      <v-container fill-height>
        <v-row
          class="full-height"
          justify="center"
        >
          <v-col
            cols="12"
            align="center"
            align-self="start"
          >
            <transition
              name="bounce"
              appear
            >
              <v-btn
                v-if="menuWindow !== 0"
                fab
                icon
                fixed
                left
                color="primary"
                class="mx-1"
                @click="menuWindow = 0"
              >
                <v-icon
                  class="white--text"
                  size="32"
                >
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </transition>
            <transition
              name="bounce"
              appear
            >
              <v-btn
                icon
                class="clay-close-btn font-weight-bold error "
                large
                @click="closeDialog()"
              >
                <v-icon class="white--text">
                  mdi-close
                </v-icon>
              </v-btn>
            </transition>
            <div align="center">
              <v-btn
                fab
                icon
                color="primary"
                class="mx-1"
                href="https://www.facebook.com/NarrativasyMemorias"
                target="_blank"
              >
                <v-icon
                  class="white--text"
                  size="32"
                >
                  mdi-facebook
                </v-icon>
              </v-btn>
              <v-btn
                fab
                icon
                color="primary"
                class="mx-1"
                href="https://twitter.com/y_memorias"
                target="_blank"
              >
                <v-icon
                  class="white--text"
                  size="32"
                >
                  mdi-twitter
                </v-icon>
              </v-btn>
              <v-btn
                fab
                icon
                color="primary"
                class="mx-1"
                href="https://www.instagram.com/narrativasymemorias"
                target="_blank"
              >
                <v-icon
                  class="white--text"
                  size="32"
                >
                  mdi-instagram
                </v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="8"
            align="center"
          >
            <v-window
              v-model="menuWindow"
              vertical
            >
              <v-window-item key="0">
                <v-list color="transparent">
                  <v-divider class="white" />
                  <v-list-item
                    class="my-4"
                    link
                    @click="menuWindow = 1"
                  >
                    <v-list-item-title class="text-h4 white--text text-uppercase">
                      Nosotros
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider class="white" />
                  <v-list-item
                    class="my-4"
                    link
                    @click="menuWindow = 2"
                  >
                    <v-list-item-title class="text-h4 white--text text-uppercase">
                      Créditos y agradecimientos
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider class="white" />
                  <v-list-item class="my-4">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text text-uppercase font-weight-bold mb-2">
                        Contacto:
                      </v-list-item-title>
                      <v-list-item-subtitle class="white--text text-subtitle-1">
                        <v-btn
                          text
                          large
                          class="text-lowercase text-subtitle-1"
                          color="white"
                          href="mailto:tacolpena@tecnicasrudas.org"
                        >
                          <v-icon class="px-2">
                            mdi-email
                          </v-icon>
                          tacolpena@tecnicasrudas.org
                        </v-btn>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-btn
                          text
                          large
                          class="text-capitalize text-subtitle-1"
                          color="white"
                          href="https://twitter.com/abuela_ruda?lang=es"
                          target="_blank"
                        >
                          <v-icon class="px-2">
                            mdi-twitter
                          </v-icon>
                          @Abuela_Ruda
                        </v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="my-4">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text text-uppercase font-weight-bold mb-2">
                        Enlaces de interés:
                      </v-list-item-title>
                      <v-list-item-subtitle class="white--text text-subtitle-1">
                        <v-btn
                          text
                          large
                          class="text-lowercase text-subtitle-1"
                          color="white"
                          href="https://raichali.com/2023/02/02/desaparecer-en-pandemia-para-comunidades-tarahumara/"
                          target="_blank"
                        >
                          “<span class="text-uppercase">D</span>
                          esaparecer en pandemia” salió de internet
                          para comunidades de la Tarahumara
                          <v-icon class="px-2">
                            mdi-open-in-new
                          </v-icon>
                        </v-btn>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-btn
                          text
                          large
                          class="text-lowercase text-subtitle-1"
                          color="white"
                          href="https://elefanteblanco.mx/2023/01/21/compartir-la-vida-en-el-norte/"
                          target="_blank"
                        >
                          <span class="text-uppercase">L</span>a lucha por la vida en la Tarahumara
                          <v-icon class="px-2">
                            mdi-open-in-new
                          </v-icon>
                        </v-btn>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-btn
                          text
                          large
                          class="text-capitalize text-subtitle-1"
                          color="white"
                          href="https://vimeo.com/797545841"
                          target="_blank"
                        >
                          Buscador@<span class="text-lowercase mr-1">s</span>
                          Nuevo León: Laura Alicia
                          <v-icon class="px-2">
                            mdi-open-in-new
                          </v-icon>
                        </v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-window-item>
              <v-window-item
                key="1"
                class="white pa-6 rounded-lg credits"
                :style="$vuetify.breakpoint.mobile ? 'height: 480px;' : 'height: 520px;'"
              >
                <div class="text-h4 text-uppercase font-weight-bold orange--text mb-2">
                  Desaparecer en pandemia
                </div>
                <div class="text-body-1 mb-8">
                  En México, comenzamos a vivir los efectos de la pandemia de COVID-19 en marzo de
                  2020. Durante este tiempo, muchas veces nos hemos preguntado qué estaba pasando
                  con
                  otros temas, como la crisis que se arrastraba por la desaparición de personas o la
                  violencia en general. Esta investigación trata de cubrir huecos sobre los cambios
                  económicos, sociales y políticos que están afectando la seguridad en México. Es
                  resultado de investigaciones en campo a nivel nacional y trabajo de gabinete,
                  contando con diferentes expertos/as.
                </div>
                <div class="text-h4 text-uppercase font-weight-bold orange--text mb-2">
                  ¿Quiénes somos?
                </div>
                <div class="text-body-1 mb-8">
                  La investigación que presentamos es un proyecto colaborativo de Técnicas Rudas
                  y la colectiva ADA, que
                  cuenta con el soporte de DW Akademie, en el que han participado un grupo de
                  investigadores independientes, periodistas, diseñadores, artistas visuales y
                  programadores mexicanos.
                </div>
                <div class="text-h4 text-uppercase font-weight-bold orange--text mb-2">
                  ¿Qué hacemos?
                </div>
                <div class="text-body-1">
                  Técnicas Rudas busca aportar a la defensa de los derechos humanos a través del
                  fortalecimiento de la organización social de base. Para ello, promovemos el uso de
                  tecnologías éticas, facilitamos la apertura y (re-)construcción colectiva del
                  conocimiento, asesoramos la consolidación de organizaciones y redes, y fomentamos
                  la
                  creación artística como herramienta de cambio social. La educación popular y una
                  perspectiva de género atraviesan todas nuestras acciones. A largo plazo, esperamos
                  ver a organizaciones sociales autónomas y sustentables salvaguardando los derechos
                  humanos y protegiendo la autodeterminación de comunidades locales.
                </div>
              </v-window-item>
              <v-window-item
                key="2"
                class="credits"
                :style="$vuetify.breakpoint.mobile ? 'height: 480px;' : 'height: 520px;'"
              >
                <div class="text-body-1 white--text mb-4">
                  Desaparecer en Pandemia es un proyecto de Técnicas Rudas y la colectiva ADA,
                  en colaboración con la DW Akademie promovido por el Ministerio Federal
                  de Cooperación Ecónomica y Desarrollo.
                </div>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Periodistas
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in periodists"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Edición de textos
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in editors"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Artistas
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in artists"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Colaboradores invitados
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in guests"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Programación y diseño de página web
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in designers"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Coordinadora de investigación
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in coords"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Asesora de investigación
                  </v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        Tamar Hayrikyan
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Coordinación de alianzas creativas
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in creatives"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Coordinadora de tecnología
                  </v-subheader>
                  <v-list-item
                    v-for="(credit, index) in techs"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list
                  color="transparent"
                  class="justify-center"
                  dense
                >
                  <v-subheader class="primary--text text-uppercase font-weight-bold justify-center">
                    Agradecimientos
                  </v-subheader>
                  <div class="text-body-1 white--text">
                    Queremos agradecer a las siguientes personas y organizaciones por compartir su
                    tiempo y experiencia con nuestro equipo de investigación a la hora de diseñar y
                    definir los enfoques del proyecto
                    <span class="font-italic">Desaparecer en Pandemia</span>:
                  </div>
                  <v-list-item
                    v-for="(credit, index) in thanks"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="white--text text-subtitle-1">
                        {{ credit }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      menuWindow: 0,
      menuNavigationDrawer: false,

      periodists: ['Carlos Manuel Juárez', 'Patricia Mayorga', 'Raúl Pérez'],
      editors: ['Valeria Enríquez'],
      artists: ['Ziumi Juárez Gámez', 'Arturo Muñoz Rodríguez el Carcará', 'Fer Fierro Zago'],
      guests: [
        'Mauricio Pimentel',
        'Patricia Rodríguez ',
        'Yolanda Abbud Lozoya',
        'Jose Luis Martínez',
        'Edgar Abdiel Múgica Morales',
        'Emmanuel Tanús',
      ],
      designers: ['Gustavo Mitre', 'Yeneli Sánchez'],
      coords: ['Philippa  Williams'],
      creatives: ['Itzell Sánchez Martínez'],
      techs: ['Mayeli Sánchez Martínez'],
      thanks: [
        'Buscadorxs México',
        'Centro para los Derechos Humanos Fray Juan de Larios',
        'Casa del Migrante de Saltillo',
        'Familias Unidas en la Búsqueda y localización de personas desaparecidas A.C. (FamUn)',
        'Las comunidades desplazadas en la Sierra Tarahumara',
        'Thelma Gómez Durán',
        'Juan Sebastián Salamanca',
        'Marcela Turati',
        'y varias personas u organizaciones quienes contribuyeron de manera anónima.',
      ],
    };
  },

  methods: {
    closeDialog() {
      this.menuWindow = 0;
      this.menuNavigationDrawer = false;
    },
  },
};
</script>
<style scoped>
    .clay-close-btn {
        position: fixed;
        top: 28px !important;
        right: 24px !important;
        z-index: 3;
    }
    .menu-bg-color {
        background-color: rgba(0, 0, 0, 0.95);
    }
    .full-height {
        height: 100%;
    }
    .socials-container {
        position: fixed;
        top: 16px;
    }
    .custom-fixed {
        z-index: 2;
    }
    .credits {
        overflow-y: scroll;
    }
</style>
