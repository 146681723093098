<template>
  <v-dialog
    :value="active"
    width="75%"
    :fullscreen="$vuetify.breakpoint.mobile"
    persistent
  >
    <v-card class="clay-card secondary pa-8 rounded-0 bg-texture">
      <v-card-title
        class="justify-center font-weight-black mb-4"
        :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2'"
      >
        INSTRUCCIONES
      </v-card-title>
      <v-card-subtitle
        class="justify-center text-h5 font-weight-bold"
        align="center"
      >
        Un tutorial de cómo navegar en la página
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="4"
            align="center"
          >
            <v-img src="@/assets/gifs/paso1.gif" />
            <v-card-title class="text-height-normal font-weight-bold">
              Da click y arrastra el cursor para navegar en el ambiente.
            </v-card-title>
          </v-col>
          <v-col
            cols="12"
            md="4"
            align="center"
          >
            <v-img src="@/assets/gifs/paso2.gif" />
            <v-card-title class="text-height-normal font-weight-bold">
              En el menú lateral derecho o en los elementos del ambiente,
              da click para conocer qué pasó ahí.
            </v-card-title>
          </v-col>
          <v-col
            cols="12"
            md="4"
            align="center"
          >
            <v-img src="@/assets/gifs/paso3.gif" />
            <v-card-title class="text-height-normal font-weight-bold">
              Da click en el segundo botón del menú lateral izquierdo
              para conocer más investigaciones.
            </v-card-title>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center pb-8">
        <v-btn
          v-if="$route.name === 'investigaciones'"
          class="clay-btn font-weight-bold primary  px-16"
          large
          @click="$emit('update:active', false)"
        >
          Entendido
        </v-btn>
        <v-btn
          v-else
          class="font-weight-bold primary  px-16"
          large
          depressed
          @click="$router.push('/investigaciones')"
        >
          Entendido
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
 .text-height-normal{
    word-break: break-word;
    line-height: normal !important;
    min-height: 128px;
 }
 .bg-texture{
    background-image: url('~@/assets/texture.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
</style>
