const createInteractionObjectAdapter = (interactionObjectDto, interactionObjectMediaType) => ({
  title: interactionObjectDto.name,
  type: interactionObjectMediaType,
  body: interactionObjectMediaType === 'investigation' ? interactionObjectDto.content : null,
  icon: `${process.env.VUE_APP_API_URL}${interactionObjectDto.icon}`,
  model: `${process.env.VUE_APP_API_URL}${interactionObjectDto.model}`,
  position: interactionObjectDto.position,
  rotation: interactionObjectDto.rotation,
  scale: interactionObjectDto.scale,
  url: `${process.env.VUE_APP_API_URL}${interactionObjectDto.url}`,
  isSvg: interactionObjectDto.isSvg,
  investigationId: interactionObjectDto.investigationId,
});

export default createInteractionObjectAdapter;
