<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
        xl="4"
        align="center"
      >
        <v-card class="opacity-card">
          <v-card-subtitle class="text-body-1 font-weight-black black--text">
            Fecha de lanzamiento: 09 de Mayo de 2022
          </v-card-subtitle>
          <v-card-title
            class="text-uppercase font-weight-bold justify-center mb-6"
            :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2'"
          >
            Desaparecer<br>en pandemia
          </v-card-title>
          <v-card-text class="text-body-1 mb-6 text-center px-8">
            La página es un
            <span class="font-weight-black">
              viaje interactivo
            </span>
            con audios, videos,            textos e imágenes que
            <span class="font-weight-black">
              atraviesan el complejo paisaje de la violencia en México
            </span>
            durante la pandemia de <span class="font-weight-black">COVID-19</span>.
          </v-card-text>
          <v-card-actions class="justify-center pb-4">
            <transition
              name="bounce"
              appear
            >
              <v-btn
                class="font-weight-bold primary  px-16"
                :class="!loadingInvestigations && 'clay-btn'"
                large
                :disabled="loadingInvestigations"
                :loading="loadingInvestigations"
                @click="openInvestigationsDialog()"
              >
                Comenzar
              </v-btn>
            </transition>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-tooltip
        left
        nudge-left="12"
      >
        <template #activator="{ on, attrs }">
          <transition
            name="bounce"
            appear
          >
            <v-btn
              icon
              class="clay-btn-icon font-weight-bold primary  ma-2"
              large
              v-bind="attrs"
              fixed
              top
              left
              v-on="on"
              @click="toggleSound()"
            >
              <v-icon class="white--text">
                {{
                  soundEnabled ? 'mdi-volume-high' : 'mdi-volume-off'
                }}
              </v-icon>
            </v-btn>
          </transition>
        </template>
        <span> {{ soundEnabled ? 'Deshabilitar audio' : 'Habilitar audio' }}</span>
      </v-tooltip>
    </v-row>
    <investigations-dialog-component
      :active.sync="investigationsDialog"
      @selected-investigation="tourDialog = true"
    />
    <tour-dialog-component :active.sync="tourDialog" />
    <footer-component />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { Howl } from 'howler';
import FooterComponent from '../components/FooterComponent.vue';
import InvestigationsDialogComponent from '../components/InvestigationsDialogComponent.vue';
import TourDialogComponent from '../components/TourDialogComponent.vue';

const bgAudio = require('@/assets/bg_audio.mp3');

export default {
  components: {
    FooterComponent,
    InvestigationsDialogComponent,
    TourDialogComponent,
  },

  beforeRouteLeave(to, from, next) {
    this.sound.stop();
    next();
  },

  data() {
    return {
      soundEnabled: false,
      investigationsDialog: false,
      tourDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      loadingInvestigations: 'getLoadingInvestigations',
    }),
  },

  mounted() {
    this.sound = new Howl({
      src: [bgAudio],
      loop: true,
    });
  },

  methods: {
    toggleSound() {
      this.soundEnabled = !this.soundEnabled;
      this.sound[this.soundEnabled ? 'play' : 'pause']();
    },

    openInvestigationsDialog() {
      this.investigationsDialog = true;
    },
  },

};
</script>
<style scoped>
    .opacity-card {
        background-color: rgba(255, 255, 255, 0.8);
    }
</style>
