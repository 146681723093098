<template>
  <v-app>
    <v-navigation-drawer
      v-if="$route.name === 'investigaciones'"
      :value="true"
      mini-variant
      fixed
      color="transparent"
      floating
      :mini-variant-width="$vuetify.breakpoint.mobile ? '80' : '110'"
      align="center"
      class="pt-4 elevation-0"
      permanent
      touchless
    >
      <v-tooltip
        right
        nudge-right="12"
      >
        <template #activator="{ on, attrs }">
          <transition
            name="bounce"
            appear
          >
            <v-btn
              icon
              class="clay-btn-icon font-weight-bold primary  ma-2 elevation-4"
              large
              v-bind="attrs"
              to="/"
              v-on="on"
            >
              <v-icon class="white--text">
                mdi-home
              </v-icon>
            </v-btn>
          </transition>
        </template>
        <span>Inicio</span>
      </v-tooltip>
      <v-tooltip
        right
        nudge-right="12"
      >
        <template #activator="{ on, attrs }">
          <transition
            name="bounce"
            appear
          >
            <v-btn
              icon
              class="clay-btn-icon font-weight-bold primary  ma-2 elevation-4"
              large
              v-bind="attrs"
              v-on="on"
              @click="investigationsDialog = true"
            >
              <v-icon class="white--text">
                mdi-file-document-multiple
              </v-icon>
            </v-btn>
          </transition>
        </template>
        <span>Investigaciones</span>
      </v-tooltip>
      <investigations-dialog-component :active.sync="investigationsDialog" />
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        icon
        class="clay-btn-icon font-weight-bold primary  ma-2 elevation-4"
        large
        @click="SET_MOBILE_MENU_ENABLED(!mobileMenuEnabled)"
      >
        <v-icon class="white--text">
          mdi-folder-multiple-image
        </v-icon>
      </v-btn>
      <v-tooltip
        right
        nudge-right="12"
      >
        <template #activator="{ on, attrs }">
          <transition
            name="bounce"
            appear
          >
            <v-btn
              icon
              class="clay-btn-icon font-weight-bold primary  ma-2 elevation-4"
              large
              v-bind="attrs"
              v-on="on"
              @click="tourDialog = true"
            >
              <v-icon class="white--text">
                mdi-help
              </v-icon>
            </v-btn>
          </transition>
        </template>
        <span>Guía de uso</span>
      </v-tooltip>
      <tour-dialog-component :active.sync="tourDialog" />
      <v-tooltip
        right
        nudge-right="12"
      >
        <template #activator="{ on, attrs }">
          <transition
            name="bounce"
            appear
          >
            <v-btn
              icon
              class="clay-btn-icon font-weight-bold primary  ma-2 elevation-4"
              large
              v-bind="attrs"
              v-on="on"
              @click="toggleSound()"
            >
              <v-icon class="white--text">
                {{
                  soundEnabled ? 'mdi-volume-high' : 'mdi-volume-off'
                }}
              </v-icon>
            </v-btn>
          </transition>
        </template>
        <span>Sonido habilitado: {{ soundEnabled ? 'Sí' : 'No' }}</span>
      </v-tooltip>
    </v-navigation-drawer>
    <menu-navigation-drawer-component />
    <v-main :class="$route.name === 'home' && 'bg-image'">
      <router-view />
      <FlipbookDialogComponent :active="isActiveModal" />
    </v-main>
  </v-app>
</template>
<script>
import AFRAME from 'aframe';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import InvestigationsDialogComponent from './components/InvestigationsDialogComponent.vue';
import MenuNavigationDrawerComponent from './components/MenuNavigationDrawerComponent.vue';
import TourDialogComponent from './components/TourDialogComponent.vue';
import FlipbookDialogComponent from './components/FlipbookDialogComponent.vue';

export default {
  name: 'App',

  components: {
    InvestigationsDialogComponent,
    TourDialogComponent,
    MenuNavigationDrawerComponent,
    FlipbookDialogComponent,
  },

  data() {
    return {
      investigationsDialog: false,
      tourDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      soundEnabled: 'getSoundEnabled',
      mobileMenuEnabled: 'getMobileMenuEnabled',
      selectedInvestigation: 'getSelectedInvestigation',
      interactionObject: 'getSelectedInteractionObject',
    }),

    isActiveModal() {
      return !!this.interactionObject && (this.interactionObject.type === 'hemeroteca' || this.interactionObject.type === 'denuncias');
    },
  },

  created() {
    const vueInstance = this;
    AFRAME.registerComponent('tooltip', {
      schema: {
        interactionObject: {
          default: '',
        },
      },
      init() {
        this.el.addEventListener('mouseenter', () => {
          vueInstance.SET_TOOLTIP({
            show: true,
            text: this.data?.interactionObject?.title,
          });
        });
        this.el.addEventListener('mouseleave', () => {
          vueInstance.SET_TOOLTIP({
            show: false,
            text: '',
          });
        });
      },
    });
  },

  async mounted() {
    await this.fetchInvestigations();
    await this.fetchImages();
    await this.fetchVideos();
    await this.fetchInterviews();
    this.SET_INTERACTION_OBJECTS();
    this.SET_LOADING_INVESTIGATIONS(false);
  },

  methods: {
    ...mapActions(['fetchInvestigations', 'fetchImages', 'fetchInterviews', 'fetchVideos']),
    ...mapMutations(['SET_LOADING_INVESTIGATIONS', 'SET_SOUND_ENABLED', 'SET_MOBILE_MENU_ENABLED', 'SET_TOOLTIP', 'SET_INTERACTION_OBJECTS']),

    toggleSound() {
      this.SET_SOUND_ENABLED(!this.soundEnabled);
    },
  },
};
</script>
<style scoped>
    .bg-image {
        background-image: url('~@/assets/portada.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>
