<template>
  <v-overlay
    :value="active"
    absolute
    z-index="10"
  >
    <flipbook
      class="flipbook"
      :pages="dialogType === 'hemeroteca' ? pages : pagesDenuncias"
      :click-to-zoom="true"
      single-page
    />
    <transition
      name="bounce"
      appear
    >
      <v-btn
        icon
        class="clay-close-btn font-weight-bold error "
        large
        @click="closeDialog()"
      >
        <v-icon class="white--text">
          mdi-close
        </v-icon>
      </v-btn>
    </transition>
  </v-overlay>
</template>
<script>
import Flipbook from 'flipbook-vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { Flipbook },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      pages: 'getPages',
      pagesDenuncias: 'getPagesDenuncias',
      interactionObject: 'getSelectedInteractionObject',
    }),

    dialogType() {
      return this.interactionObject?.type ?? '';
    },
  },

  methods: {
    ...mapMutations(['SET_SELECTED_INTERACTION_OBJECT']),

    closeDialog() {
      this.SET_SELECTED_INTERACTION_OBJECT(null);
    },
  },
};
</script>
<style scoped>
    .flipbook {
        width: 75vw;
        height: 75vh;
        z-index: 9999 !important;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .clay-close-btn {
        box-shadow: inset 3px 2px 12px var(--v-error-lighten5),
         inset -3px -2px 12px var(--v-error-base),
         6px 6px 16px rgb(36, 36, 36) !important;
        border-radius: 16px;
        position: fixed;
        top: 24px !important;
        right: 24px !important;
    }
    .grayscale {
        backdrop-filter: grayscale(100%);
        filter: grayscale(1);
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
