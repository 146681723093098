const createImageAdapter = (imageApiDto) => {
  const {
    id,
    attributes: {
      name,
      content: {
        data: {
          attributes: {
            url,
          },
        },
      },
      icon: {
        data: {
          attributes: {
            url: icon,
          },
        },
      },
      model: {
        data: {
          attributes: {
            url: model,
          },
        },
      },
      position,
      rotation,
      scale,
      investigation: {
        data: {
          id: investigationId,
        },
      },
      isSvg,
    },
  } = imageApiDto;

  return {
    id,
    name,
    url,
    icon,
    model,
    position,
    rotation,
    scale,
    investigationId,
    isSvg,
  };
};

export default createImageAdapter;
