<template>
  <v-row class="align-center">
    <v-col
      cols="12"
      md="4"
      offset-md="1"
      align="center"
    >
      <av-circle
        ref-link="interview"
        :canv-width="360"
        :canv-height="360"
        :line-width="1"
        :line-space="0.4"
        :outline-color="$vuetify.theme.themes.light.primary"
        :outline-width="4"
        :bar-width="4"
        :bar-color="$vuetify.theme.themes.light.primary"
        :progress-width="4"
        :progress-color="$vuetify.theme.themes.light.secondary"
      />
    </v-col>
    <v-col
      offset-md="1"
      :align="$vuetify.breakpoint.mobile && 'center'"
    >
      <div
        class="font-weight-bold white--text"
        :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2'"
      >
        {{ interactionObject.title }}
      </div>
      <div
        class="white--text mb-4 mt-1"
        :class="$vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h5'"
      >
        {{ selectedInvestigation.name }}
      </div>
      <audio
        ref="interview"
        :src="interactionObject.url"
        controls
        preload="metadata"
        crossorigin="anonymous"
      >
        <track kind="captions">
      </audio>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    interactionObject: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapGetters({
      selectedInvestigation: 'getSelectedInvestigation',
    }),
  },
};
</script>
