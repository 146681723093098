<template>
  <v-row
    class="align-center"
    justify="center"
  >
    <v-col
      cols="12"
      md="10"
    >
      <v-card
        tile
        :class="getBackgroundImgClass"
        class="overflow-scroll"
      >
        <v-card-title
          class="font-weight-bold text-h4 investigation-title"
          :class="!$vuetify.breakpoint.mobile && 'ma-12'"
        >
          <v-row>
            <v-col cols="12">
              <span>{{ interactionObject.title }}</span>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="clay-btn-icon font-weight-bold primary  mt-2"
                small
                :href="`/pdf/${pdfName}`"
                target="_blank"
              >
                Descargar <v-icon class="ml-2">
                  mdi-file-download
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <div
            :class="!$vuetify.breakpoint.mobile ? 'pa-12' : 'text-body-1 font-weight-bold'"
            v-html="interactionObject.body"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    interactionObject: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapGetters({
      selectedInvestigation: 'getSelectedInvestigation',
    }),

    getBackgroundImgClass() {
      switch (this.selectedInvestigation.id) {
        case 1:
          return 'background-img-tala';
        case 2:
          return 'background-img-juarez';
        case 3:
          return 'background-img-carretera';
        case 4:
          return 'background-img-reclutamiento';
        default:
          return '';
      }
    },

    pdfName() {
      switch (this.selectedInvestigation.id) {
        case 1:
          return 'la-pandemia-invisible.pdf';
        case 2:
          return 'escombros-de-violencia.pdf';
        case 3:
          return 'desaparecer-entre-las-mercancias.pdf';
        case 4:
          return 'reclutamiento-en-linea.pdf';
        default:
          return '';
      }
    },
  },
};
</script>
<style scoped>
    .investigation-title {
        word-break: break-word;
        min-height: 128px;
    }
    .overflow-scroll {
        overflow-y: scroll;
        height: 500px;
    }
    .background-img-tala {
        background-image: url('~@/assets/tala.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .background-img-juarez {
        background-image: url('~@/assets/juarez.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .background-img-reclutamiento {
        background-image: url('~@/assets/reclutamiento.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .background-img-carretera {
        background-image: url('~@/assets/carretera.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>
