const createInvestigationAdapter = (investigationApiDto) => {
  const {
    id,
    attributes: {
      name,
      background_image: {
        data: {
          attributes: {
            url: backgroundImage,
          },
        },
      },
      background_audio: {
        data: {
          attributes: {
            url: backgroundAudio,
          },
        },
      },
      content,
      icon: {
        data: {
          attributes: {
            url: icon,
          },
        },
      },
      model: {
        data: {
          attributes: {
            url: model,
          },
        },
      },
      position,
      rotation,
      scale,
      isSvg,
    },
  } = investigationApiDto;

  return {
    id,
    name,
    backgroundImage,
    backgroundAudio,
    content,
    icon,
    model,
    position,
    rotation,
    scale,
    isSvg,
  };
};

export default createInvestigationAdapter;
