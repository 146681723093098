<template>
  <a-scene
    cursor="rayOrigin: mouse"
    raycaster="objects: .raycastable"
    loading-screen="enabled: false"
  >
    <a-assets
      @loaded="isLoaded = true"
    >
      <template v-for="item, index in interactionObjects">
        <a-asset-item
          v-if="!item.isSvg"
          :id="item.id"
          :key="index"
          :src="item.model"
        />
      </template>
      <audio
        id="background-audio"
        :src="backgroundAudio"
        crossorigin="anonymous"
      ><track kind="captions"></audio>
      <img
        id="sierra"
        :src="skySierra"
        alt="background"
        crossorigin="anonymous"
      >
      <img
        id="carretera"
        :src="skyCarretera"
        alt="background"
        crossorigin="anonymous"
      >
      <img
        id="juarez"
        :src="skyJuarez"
        alt="background"
        crossorigin="anonymous"
      >
      <img
        id="reclutamiento"
        :src="skyReclutamiento"
        alt="background"
        crossorigin="anonymous"
      >
    </a-assets>
    <template v-if="isLoaded">
      <a-entity sound="src: #background-audio; autoplay: true;" />
      <a-light
        type="ambient"
        color="#fff"
        intensity="2"
      />
      <a-sky
        id="image-360"
        radius="10"
        :src="getSkyImage"
      />
      <AObjectModel
        v-for="interactionObject, index in interactionObjects"
        :key="index"
        :item="interactionObject"
        :tooltip="{ interactionObject }"
        class="raycastable"
      />
      <InteractionObjectDialogComponent />
      <InteractionObjectsDrawerComponent v-show="!isMobile" />
      <v-tooltip
        :position-x="mouseX"
        :position-y="mouseY"
        :value="tooltip.show"
        bottom
        color="black"
      >
        <span>{{ tooltip.text }}</span>
      </v-tooltip>
      <v-bottom-sheet
        v-if="isMobile"
        :value="mobileMenuEnabled"
        fullscreen
        persistent
      >
        <v-btn
          icon
          class="clay-close-btn font-weight-bold error"
          large
          @click="SET_MOBILE_MENU_ENABLED(false)"
        >
          <v-icon class="white--text">
            mdi-close
          </v-icon>
        </v-btn>
        <v-card
          color="transparent"
          align="center"
        >
          <InteractionObjectsDrawerComponent />
        </v-card>
        <v-overlay :value="true" />
      </v-bottom-sheet>
    </template>
    <div
      v-else
      class="container fill-height"
    >
      <div class="row">
        <div class="col-12 text-center">
          <v-progress-circular
            :size="50"
            width="6"
            color="primary"
            indeterminate
          />
          <div class="text-h4 mt-4 font-weight-bold">
            Cargando ambiente...
          </div>
        </div>
      </div>
    </div>
  </a-scene>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AObjectModel from '../components/atoms/AObjectModel.vue';
import InteractionObjectDialogComponent from '../components/InteractionObjectDialogComponent.vue';
import InteractionObjectsDrawerComponent from '../components/InteractionObjectsDrawerComponent.vue';
import skyCarretera from '../assets/sky/carretera.png';
import skyJuarez from '../assets/sky/cd_juarez.png';
import skyReclutamiento from '../assets/sky/reclutamiento.png';
import skySierra from '../assets/sky/sierra.png';
import audioCarretera from '../assets/background-audios/carretera.wav';
import audioJuarez from '../assets/background-audios/cd_juarez.mp3';
import audioReclutamiento from '../assets/background-audios/reclutamiento.mp3';
import audioSierra from '../assets/background-audios/sierra.wav';

export default {
  components: {
    AObjectModel,
    InteractionObjectDialogComponent,
    InteractionObjectsDrawerComponent,
  },

  data() {
    return {
      interactionObjectDialog: false,
      mouseX: 0,
      mouseY: 0,
      showTooltip: false,
      tooltipTitle: 'Test',
      skyCarretera,
      skyJuarez,
      skyReclutamiento,
      skySierra,
      isLoaded: false,
    };
  },

  computed: {
    ...mapGetters({
      interactionObjects: 'getInteractionObjects',
      backgroundImage: 'getInvestigationBackgroundImage',
      backgroundAudio: 'getInvestigationBackgroundAudio',
      tooltip: 'getTooltip',
      soundEnabled: 'getSoundEnabled',
      selectedInteractionObject: 'getSelectedInteractionObject',
      selectedInvestigation: 'getSelectedInvestigation',
      mobileMenuEnabled: 'getMobileMenuEnabled',
    }),

    isMobile() {
      return this.$device.mobile;
    },

    getSkyImage() {
      switch (this.selectedInvestigation.id) {
        case 1:
          return '#carretera';
        case 2:
          return '#juarez';
        case 3:
          return '#reclutamiento';
        case 4:
          return '#sierra';
        default:
          return '#carretera';
      }
    },

    backgroundAudio() {
      switch (this.selectedInvestigation.id) {
        case 1:
          return audioCarretera;
        case 2:
          return audioJuarez;
        case 3:
          return audioReclutamiento;
        case 4:
          return audioSierra;
        default:
          return audioCarretera;
      }
    },
  },

  watch: {
    backgroundImage(newValue, oldValue) {
      this.$router.go();
    },

    soundEnabled(newValue) {
      const entity = document.querySelector('[sound]');
      if (entity) {
        if (newValue && this.soundEnabled) {
          entity.components.sound.playSound();
        } else {
          entity.components.sound.pauseSound();
        }
      }
    },

    selectedInteractionObject(newValue) {
      const entity = document.querySelector('[sound]');
      if (!newValue && this.soundEnabled) {
        entity.components.sound.playSound();
      } else {
        entity.components.sound.pauseSound();
      }
    },
  },

  created() {
    window.addEventListener('mousemove', this.setMousePosition);
    this.SET_SOUND_ENABLED(true);
  },

  methods: {
    ...mapMutations(['SET_MOBILE_MENU_ENABLED', 'SET_SOUND_ENABLED']),
    setMousePosition(e) {
      this.mouseX = e.pageX;
      this.mouseY = e.pageY + 20;
    },
  },
};
</script>

<style scoped>
    .floating-card {
        position: fixed;
        top: 16px;
        left: 16px;
        width: 320px;
    }
    .fixed-text {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 4s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .clay-close-btn {
        position: fixed;
        top: 24px !important;
        right: 24px !important;
        z-index: 1000;
    }
</style>
